import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CTA from "../shared/sub/cta"

const HomeCTAS = () => {
  const {
    sanityHomePage: {
      ctas: { ctas },
    },
  } = useStaticQuery(graphql`
    query {
      sanityHomePage {
        ctas {
          ctas {
            _key
            image {
              ...sanityFlightImage
            }
            imageSize
            leftRatio
            reverse
            rightRatio
            text {
              _rawContent(resolveReferences: { maxDepth: 5 })
            }
            topBottomSpace
          }
        }
      }
    }
  `)

  return (
    <div>
      {ctas.map(cta => (
        <CTA key={cta._key} {...cta} />
      ))}
    </div>
  )
}

export default HomeCTAS
