import React from 'react'
import { Helmet } from "react-helmet"

interface Props {
  title?: string
  slug?: string
  description?: string
  keywords?: string
  image?: any
  blog?: boolean | false
}

const SEO:React.FC<Props> = ({title, slug, description, keywords, image, blog}) => {

  return (
    <div className="seo">
      <Helmet title={title || ""}>
        <html lang="en" />
        {description &&     <meta name="description" content={description} />}
        {keywords &&        <meta name="keywords" content={keywords} />}


        {title &&           <meta property="og:title" content={title} />}
                            <meta property="og:type" content={blog ? "article" : "website"} />
        {slug &&            <meta property="og:url" content={slug} />}
        {image &&           <meta property="og:image" content={image.asset.url} />}
        {description &&     <meta property="og:description" content={description} />}


        {/* Twitter Card tags */}
        {description &&     <meta name="twitter:card" content={description} />}
        {title &&           <meta name="twitter:title" content={title} />}
        {image &&           <meta name="twitter:image" content={image.asset.url} />}
        {slug &&            <meta name="twitter:site" content={slug} />}
        {description &&     <meta name="twitter:description" content={description} />}
      </Helmet>
    </div>
  )
}

export default SEO

export const seoDataFormat = (seo: any) => {
  return {
    title: seo.pageTitle,
    slug: seo.slug.current,
    description: seo.pageDescription,
    keywords: seo.pageKeyWords,
    image: seo.ogImage,
  }
}