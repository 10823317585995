import React, { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Carousel } from "antd";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import MyLink from "../sub/myLink";
import TestimonialSection from "./indexStyle";

interface Props {
  color: "white" | "red";
}

const TestimonialsCarousel: React.FC<Props> = ({ color }) => {
  const carouselSlider = useRef(null);

  const { left, right } = useStaticQuery(graphql`
    query {
      left: sanitySharedSection {
        side: testimonialsCarousel {
          deprecatedTitle
          link {
            url
            linkText
          }
        }
      }
      right: sanityTestimonialsPage {
        testimonials {
          _key
          name
          position
          testimonial
        }
      }
    }
  `);

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1001,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <TestimonialSection color={color}>
      <div className="container">
        <div className="left">
          <h2>{left.side.title}</h2>
          <MyLink
            className={`theme-btn inline ${color === "white" ? "red" : "white"}`}
            url={left.side.link.url}
          >
            {left.side.link.linkText}
          </MyLink>

          <div className="arrow-buttons">
            <button
              onClick={() => {
                carouselSlider.current.prev();
              }}
            >
              <AiOutlineLeft />
            </button>
            <button
              onClick={() => {
                carouselSlider.current.next();
              }}
            >
              <AiOutlineRight />
            </button>
          </div>
        </div>

        <div className="right">
          <Carousel {...carouselSettings} ref={carouselSlider}>
            {right.testimonials.map(testimonial => (
              <div className="testimonial" key={testimonial._key}>
                <p>{testimonial.testimonial}</p>
                <span className="name">{testimonial.name}</span>
                <span className="position">{testimonial.position}</span>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </TestimonialSection>
  );
};

export default TestimonialsCarousel;
