import styled from "styled-components"

export const HomeHeroHeader = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 1170px;
  margin-top: 200px;
  margin-bottom: 105px;

  @media (max-width: 1000px) {
    margin-top: 100px;
    margin-bottom: 105px;
  }
  .background-image {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .content {
    position: relative;
    text-align: center;
    width: 100%;

    p {
      color: var(--red);
      width: 66%;
      margin: auto;
      font-size: 18px;
      line-height: 25px;

      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 25px;
        width: 80%;
      }
    }

    h1 {
      color: var(--red);
      font-size: 90px;
      font-weight: 700;
      line-height: 108px;
      font-family: "medium";
      margin-bottom: 50px;
      @media (max-width: 1000px) {
        font-size: 50px;
        line-height: 57px;
        margin-bottom: 30px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      justify-content: center;
      margin-top: 50px;
      @media (max-width: 1000px) {
        margin-top: 30px;
      }

      a {
        height: fit-content;
      }
    }
  }

  svg.scroll-down {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1;
  }
`
