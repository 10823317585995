import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import MyLink from "../shared/sub/myLink"
import { HomeHeroHeader } from "./heroHeaderStyle"
import ContentBlock from "@components/shared/sub/contentBlock"
import { Query } from "@graphql-types"

const HeroHeader = () => {
  const data: Query = useStaticQuery(graphql`
    query {
      sanityHomePage {
        homeHeroHeader {
          heroText {
            _key
            _type
            _rawContent(resolveReferences: { maxDepth: 5 })
          }
          buttons {
            _key
            linkText
            url
          }
        }
      }
    }
  `)

  const scrolling = () => {
    window.scroll({
      top: window.innerHeight - 70,
      left: 0,
      behavior: "smooth",
    })
  }

  const { sanityHomePage } = data

  if (sanityHomePage == null || sanityHomePage.homeHeroHeader == null)
    return null

  const { homeHeroHeader } = sanityHomePage

  const { heroText, buttons } = homeHeroHeader

  return (
    <HomeHeroHeader>
      <div className="content">
        {heroText && <ContentBlock blocks={heroText._rawContent} />}
        {buttons && (
          <div className="buttons">
            {buttons.map(button => {
              if (button == null) return null
              return (
                <MyLink
                  key={button._key}
                  className="theme-btn block red"
                  url={button.url}
                >
                  {button.linkText}
                </MyLink>
              )
            })}
          </div>
        )}
      </div>
    </HomeHeroHeader>
  )
}

export default HeroHeader
