import React from "react";
import { Carousel } from "antd";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import ArrowWrapper from "./arrowWrapper";
import { Image } from "@global";

interface Props {
  partners: Array<any>;
  noPadding?: boolean;
  disableAutoplay?: boolean;
}

const PartnersCarousel: React.FC<Props> = ({ partners, disableAutoplay }) => {
  const setCarouselLength = partners.length === 1 ? 1 : partners.length < 4 ? 2 : 4;

  const settings = {
    autoplay: !disableAutoplay,
    autoplaySpeed: 2000,
    slidesToShow: setCarouselLength,
    rows: partners.length < 8 ? 1 : 2,
    dots: false,
    arrows: true,
    nextArrow: (
      <ArrowWrapper>
        <AiOutlineRight />
      </ArrowWrapper>
    ),
    prevArrow: (
      <ArrowWrapper>
        <AiOutlineLeft />
      </ArrowWrapper>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: partners.length < 3 ? partners.length : 3,
        },
      },
    ],
  };

  return (
    <div className="partners-carousel">
      <Carousel {...settings}>
        {partners.map(partner =>
          partner.partnerLink ? (
            <a href={partner.partnerLink} target="_blank">
              <Image key={partner._key} data={partner.partnerLogo} />
            </a>
          ) : (
            <Image key={partner._key} data={partner.partnerLogo} />
          ),
        )}
      </Carousel>
    </div>
  );
};

export default PartnersCarousel;
