import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Tabs } from "antd"
import PartnersCarousel from "./partnersCarousel"
import MyLink from "../sub/myLink"

import PartnersSection from "./indexStyle"

const { TabPane } = Tabs

interface Props {
  backgroundColor?: boolean
  hasBottomLink?: boolean | false
  padding?: string
}

const Partners: React.FC<Props> = props => {
  const {
    sanitySharedSection: { allPartners },
  } = useStaticQuery(graphql`
    query {
      sanitySharedSection {
        allPartners {
          title
          partnersLists {
            _key
            categoryTitle
            partners {
              _key
              partnerName
              partnerLogo {
                ...sanityFlightImage
              }
            }
          }
          link {
            linkText
            url
          }
        }
      }
    }
  `)

  return (
    <PartnersSection
      padding={props.padding}
      backgroundColor={props.backgroundColor}
    >
      <div className="container">
        <h3 className="title">{allPartners.title}</h3>
        <Tabs defaultActiveKey="1" centered>
          {allPartners.partnersLists.map((partnersList, index) => (
            <TabPane tab={partnersList.categoryTitle} key={index + 1}>
              <PartnersCarousel partners={partnersList.partners} />
            </TabPane>
          ))}
        </Tabs>
        {props.hasBottomLink && (
          <MyLink className="bottom-link" url={allPartners.link.url}>
            {allPartners.link.linkText}
          </MyLink>
        )}
      </div>
    </PartnersSection>
  )
}

export default Partners
