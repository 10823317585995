import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import CalculatorBlockNavStyle from "./indexStyle"
import MyLink from '../../shared/sub/myLink'

const CalculatorBlockNav = () => {
  const {
    sanitySharedSection: {
      calculatorTabs: { calculatorTabs },
    },
  } = useStaticQuery(graphql`
    query {
      sanitySharedSection {
        calculatorTabs {
          calculatorTabs {
            _key
            linkText
            url
          }
        }
      }
    }
  `)

  return (
    <CalculatorBlockNavStyle>
      <div className="container">
        <h3>Calculators</h3>
        <div className="links">
          {calculatorTabs.map(calculatorTab => (
            <div key={calculatorTab._key} className="link">
              <h4>{calculatorTab.linkText}</h4>
              <MyLink className="theme-btn inline white" url={calculatorTab.url}>Go to Calculator &gt;</MyLink>
            </div>
          ))}
        </div>
      </div>
    </CalculatorBlockNavStyle>
  )
}

export default CalculatorBlockNav
