import styled from "styled-components"

export default styled.div`
  margin: 5rem 0;

  .container {
    display: block;
    justify-content: center;
    max-width: 1500px;
    background-color: var(--red);
    padding: 8rem;
    border-radius: 6px;
    color: white;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    @media (max-width: 1100px) {
      padding: 4rem;
    }

    a {
      opacity: 0.6;
    }

    h3 {
      margin-right: 15rem;
      margin-bottom: 50px;
      font-size: 2.4rem;
      font-family: medium;
      color: white;

      @media (max-width: 1200px) {
        margin-right: 0;
        margin-bottom: 3rem;
        margin-left: 0rem;
      }

      @media (min-width: 1200px) {
        margin-right: 15rem;
      }
    }

    .links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 3rem;

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
      }

      .link {
        h4 {
          font-size: 1.6rem;
          margin-bottom: 0.4rem;
          font-family: regular;
          color: white;
        }

        a:hover {
          opacity: 1 !important;
        }

        &:not(:last-child) {
          padding-right: 3rem;
          border-right: 1px solid rgba(255, 255, 255, 0.5);

          @media (max-width: 800px) {
            padding-right: 0;
            border-right: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
`
