import styled from "styled-components"
import bkWhiteLogo from "./bkWhiteLogo.svg"
import bkRedLogo from "./bkRedLogo.svg"

export default styled.section<{ color: "white" | "red" }>`
  padding: 10rem 0;
  background-color: ${props =>
    props.color === "white" ? "rgba(85, 30, 41, 0.05)" : "var(--red)"};
  
  background-repeat: no-repeat;
  background-position: -15% 35%;
  background-size: 60%;

  @media only screen and (max-width: 1000px) {
    background-position: 200% -10%;
    background-size: 90%;
  }

  * {
    color: ${props =>
      props.color === "white" ? "var(--red)" : "var(--white)"};
  }

  .container {
    display: grid;
    grid-template-columns: 30% 70%;
    max-width: 1200px;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 100%;
    }

    .left {
      .theme-btn {
        text-decoration: underline;
      }
      .arrow-buttons {
        display: flex;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.2rem;
          height: 3.2rem;
          margin-top: 2rem;
          background-color: transparent;
          border-width: 1px;
          border-style: solid;
          border-color: ${props =>
            props.color === "white" ? "var(--red)" : "var(--white)"};
          border-radius: 0.2rem;
          cursor: pointer;

          &:last-child {
            margin-left: 1.5rem;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            background-color: ${props =>
              props.color === "white" ? "var(--red)" : "var(--white)"};

            svg {
              fill: ${props =>
                props.color === "white" ? "var(--white)" : "var(--red)"};
            }
          }
        }
      }
    }

    .right {
      width: 100%;

      .testimonial {
        padding: 0 3rem;

        @media only screen and (max-width: 1000px) {
          padding: 0;
          margin-top: 3rem;
        }

          p {
            margin-bottom: 2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 8; /* number of lines to show */
            -webkit-box-orient: vertical;

            @media only screen and (max-width: 1000px) {
              padding: 0 1px;
            }
          }
        }

        span {
          display: block;
          font-family: bold;
        }
      }
    }
  }
`
