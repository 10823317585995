import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
  style?: any;
  children?: ReactNode;
}

const RightBtn:React.FC<Props> = ({ className, onClick, style, children }) => {
  return (
    <div className={className} onClick={onClick} style={style}>
      {children}
    </div>
  )
}

export default RightBtn
