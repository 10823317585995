import React from "react"
import { graphql, PageProps } from "gatsby"

import HomeSEO from "../components/home/homeSEO"
import HeroHeader from "../components/home/heroHeader"
import Brief from "../components/home/brief"

import HomeCTAS from "../components/home/ctas"
import CalculatorBlockNav from "../components/calculators/calculatorBlockNav"
import Partners from "../components/shared/partners"
import TestimonialsCarousel from "../components/shared/testimonials"
import BlogsSection from "../components/shared/blogsSection"
import { Query } from "@graphql-types"
import { ServicesBrief } from "@global"

interface Props extends PageProps {
  data: Query
}

const HomePage = ({ data }: Props) => {
  if (data.sanitySharedSection == null) return null

  const {
    servicesBrief,
    servicesTabs,
    calculatorTabs,
    testimonialsCarousel,
    newsletterBlock,
  } = data.sanitySharedSection

  return (
    <>
      <HomeSEO />
      <HeroHeader />
      {/* <Brief /> */}
      {servicesBrief && <ServicesBrief data={servicesBrief} />}
      <CalculatorBlockNav />
      <HomeCTAS />
      <Partners hasBottomLink padding="2rem 0px 13rem 0px" />
      <TestimonialsCarousel color="red" />
      <BlogsSection title="Blogs" category="All" />
    </>
  )
}

export const query = graphql`
  query {
    sanitySharedSection {
      servicesBrief {
        ...sanityServicesBrief
      }
    }
  }
`

export default HomePage
